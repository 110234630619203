import React, {useEffect, useState} from "react"
import General2024 from './guide/2024-general'
const GuidePage = () => {
 return (
  <>
    <General2024 />
  </>
 )
}

export default GuidePage
